import { Component, OnInit } from "@angular/core";
import { AuthGuardService } from "./../../servicios/auth-guard.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap/modal/modal";
import { NgbModalRef } from "@ng-bootstrap/ng-bootstrap/modal/modal-ref";
import { LectorService } from "app/servicios/lector.service";
import { FormsModule } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import "rxjs/add/operator/take";
import { ToastrService } from "ngx-toastr";
declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  usuario: string;
  tipo: string;
}

export const ROUTES: RouteInfo[] = [];

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"],
})
export class SidebarComponent implements OnInit {
  menuItems: any[];
  usuario;
  modalRef: NgbModalRef;

  constructor(public ag: AuthGuardService, private modalService: NgbModal) {}

  async ngOnInit() {
    this.menuItems = ROUTES.filter((menuItem) => menuItem);
    await this.ag.getAuth();
    this.usuario = this.ag.tipo;
  }

  isMobileMenu() {
    if (window.innerWidth > 991) {
      return false;
    }
    return true;
  }

  async openModal(content) {
    this.modalRef = this.modalService.open(content, {
      size: "sm",
      centered: true,
    });
  }

  salir() {
    this.ag.logout();
    this.modalRef.close();
  }

  async delete(confirm) {
    this.modalService.open(confirm).result.then((result) => {
      if (result === "Eliminar Cuenta") {
        console.log("Cuenta eliminada");
        // Call your deletion logic here
      } else {
        console.log("Operación cancelada");
      }
    });
    // NgbModal
    // const alert = await this.alert_controller.create({
    //   header: '¡Atención!',
    //   message: 'Seguro que deseas empezar el proceso de eliminacion de cuenta?, este proceso puede que no sea revertible y pierdas tus invitaciones y accesos',
    //   buttons: [
    //     {
    //       text: 'Eliminar Cuenta',
    //       role: 'confirm',
    //       cssClass: 'primary',
    //       handler: async () => {
    //         console.log(1);
    //       }
    //     },
    //     {
    //       text: 'Cancelar',
    //       role: 'cancel',
    //       cssClass: 'primary',
    //       handler: () => {
    //         console.log('a ver que hace');
    //       }
    //     }
    //   ]
    // });

    // await alert.present();
  }
}
