import { Injectable } from '@angular/core';
import {Http, Headers} from '@angular/http';
import {FormGroup,FormControl,Validators} from '@angular/forms';
import { AngularFireDatabase, AngularFireAction } from 'angularfire2/database';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from 'angularfire2/firestore';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import { AuthGuardService } from './auth-guard.service';

@Injectable({
  providedIn: 'root'
})
export class CalleService {
  privadasCollection;
  privada;
  privadas;
  callesCollection;
  calles;

  constructor(private _afs:AngularFirestore,private ag:AuthGuardService)
  { }

  /**
  * metodo de obtiene las calles de una privada
  * @param nombrePrivada nombre de la privada
  * @returns todas las calles dentro de una privada
  **/
  getCallesPrivada(nombrePrivada){
    this.callesCollection = this._afs.collection('calles',x => x.where('nombrePrivada','==',nombrePrivada));
    this.calles = this.callesCollection.snapshotChanges().map(changes => {
      return changes.map(a => {
        const data = a.payload.doc.data();
        data.id = a.payload.doc.id;
        return data;
      });
    });
    return this.calles;
  }

  /**
  * metodo que obtiene las calles de una privada
  * @returns calles de una privada
  **/
  getCalles(){
    this.callesCollection = this._afs.collection('calles',ref => ref.where("idPrivada","==",this.ag.tipo.privada));

    this.calles = this.callesCollection.snapshotChanges().map(changes => {
      return changes.map(a => {
        const data = a.payload.doc.data();
        data.id = a.payload.doc.id;
        return data;
      });
    });

    return this.calles;
  }

  /**
  * metodo de obtiene una calle
  * @param id identificador de una calle
  * @returns una calle
  **/

  getCalle(id){
    this.callesCollection = this._afs.collection('calles');
    return this.callesCollection.doc(id).valueChanges();
  }
  /**
  * metodo que guarda una calle
  * @param calle una calle
  **/
  guardar(calle) {
    calle.idPrivada=this.ag.tipo.privada;
    calle.nombrePrivada=this.ag.tipo.nombrePrivada;
    this.callesCollection = this._afs.collection('calles');
    this.callesCollection.add(calle);
  }
  
  /**
  * metodo de que permite editar una calle
  * @param calle calle
  * @param idCalle identificador de calle
  **/
  editar(idCalle, calle) {
      this.callesCollection = this._afs.collection('calles');
      this.callesCollection.doc(idCalle).update(calle);
  }

}
