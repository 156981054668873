import { Component} from '@angular/core';
import {AuthGuardService} from './servicios/auth-guard.service';
import { environment } from '../environments/environment';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Portero';
  constructor() {
  }

}
