import { Injectable } from "@angular/core";
import { AngularFireDatabase, AngularFireList } from "angularfire2/database";
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
} from "angularfire2/firestore";
import { Observable } from "rxjs/Observable";
import { Usuario } from "./../modelos/usuario";
import { AngularFireStorage } from "angularfire2/storage";
import { AuthGuardService } from "./auth-guard.service";
import { Router } from "@angular/router";

@Injectable()
export class UsuarioService {
  usuariosCollection: AngularFirestoreCollection<Usuario>;
  usuarios;
  usuarioDoc: AngularFirestoreDocument<Usuario>;

  constructor(
    private sg: AngularFireStorage,
    private bd: AngularFireDatabase,
    public router: Router,
    public _afs: AngularFirestore,
    private ag: AuthGuardService
  ) {
    this.getUsuarios();
  }

  getColonos(privada) {
    this.usuariosCollection = this._afs.collection("usuario", (ref) =>
      ref.where("privada", "==", privada).where("tipoUsuario", "==", "5")
    );

    this.usuarios = this.usuariosCollection.snapshotChanges().map((changes) => {
      return changes.map((a) => {
        const data = a.payload.doc.data() as Usuario;
        data.id = a.payload.doc.id;
        return data;
      });
    });

    return this.usuarios;
  }

  getHabitantes(idCasa) {
    this.usuariosCollection = this._afs.collection("usuario", (ref) =>
      ref.where("idCasa", "==", idCasa)
    );

    this.usuarios = this.usuariosCollection.snapshotChanges().map((changes) => {
      return changes.map((a) => {
        const data = a.payload.doc.data() as Usuario;
        data.id = a.payload.doc.id;
        return data;
      });
    });

    return this.usuarios;
  }

  getGuardiasCaseta(idCaseta) {
    this.usuariosCollection = this._afs.collection("usuario", (ref) =>
      ref.where("idCaseta", "==", idCaseta)
    );

    this.usuarios = this.usuariosCollection.snapshotChanges().map((changes) => {
      return changes.map((a) => {
        const data = a.payload.doc.data() as Usuario;
        data.id = a.payload.doc.id;
        return data;
      });
    });

    return this.usuarios;
  }

  async existeColono(correo, nombrePrivada) {
    let resultado;
    await this._afs
      .collection("usuario", (x) =>
        x
          .where("correo", "==", correo)
          .where("nombrePrivada", "==", nombrePrivada)
      )
      .valueChanges()
      .take(1)
      .toPromise()
      .then((y) => {
        if (y.length == 0) {
          resultado = false;
        } else {
          resultado = true;
        }
      });
    return resultado;
  }

  async existeCelular(celular) {
    let resultado;
    await this._afs
      .collection("usuario", (x) => x.where("celular", "==", celular))
      .valueChanges()
      .take(1)
      .toPromise()
      .then((y) => {
        if (y.length == 0) {
          resultado = false;
        } else {
          resultado = true;
        }
      });
    return resultado;
  }

  async existeCorreo(correo) {
    let resultado;
    await this._afs
      .collection("usuario", (x) => x.where("correo", "==", correo))
      .valueChanges()
      .take(1)
      .toPromise()
      .then((y) => {
        if (y.length == 0) {
          resultado = false;
        } else {
          resultado = true;
        }
      });
    return resultado;
  }

  async existeColonoCelular(celular, nombrePrivada) {
    let resultado;
    await this._afs
      .collection("usuario", (x) =>
        x
          .where("celular", "==", celular)
          .where("nombrePrivada", "==", nombrePrivada)
      )
      .valueChanges()
      .take(1)
      .toPromise()
      .then((y) => {
        if (y.length == 0) {
          resultado = false;
        } else {
          resultado = true;
        }
      });
    return resultado;
  }

  async existeInvitado(correo) {
    let resultado;
    await this._afs
      .collection("usuario", (x) => x.where("correo", "==", correo))
      .valueChanges()
      .take(1)
      .toPromise()
      .then((y) => {
        if (y.length == 0) {
          resultado = false;
        } else {
          resultado = true;
        }
      });
    return resultado;
  }
  async existeInvitadoCelular(celular) {
    let resultado;
    await this._afs
      .collection("usuario", (x) => x.where("celular", "==", celular))
      .valueChanges()
      .take(1)
      .toPromise()
      .then((y) => {
        if (y.length == 0) {
          resultado = false;
        } else {
          resultado = true;
        }
      });
    return resultado;
  }

  async getUsariosCorreo(correo) {
    const query = this._afs
      .collection("usuario")
      .ref.where("correo", "==", correo);
    const snapshot = await query.get();
    snapshot.docs.map((doc) => ({
      id: doc.id,
    }));
    const idColono = snapshot.docChanges[0].doc.id;
    this.usuariosCollection = this._afs.collection("usuario", (x) =>
      x.orderBy("nombre", "asc")
    );
    return idColono;
  }

  async getUsuarioCorreo(correo) {
    const query = this._afs
      .collection("usuario")
      .ref.where("correo", "==", correo);
    const snapshot = await query.get();
    snapshot.docs.map((doc) => ({
      id: doc.id,
    }));
    const idColono = snapshot.docChanges[0].doc.id;
    this.usuariosCollection = this._afs.collection("usuario", (x) =>
      x.orderBy("nombre", "asc")
    );
    return this.usuariosCollection.doc(idColono).valueChanges();
  }

  async getUsuarioCelular(celular) {
    //console.log(celular);
    const query = await this._afs
      .collection("usuario")
      .ref.where("celular", "==", celular);
    //console.log(query);
    const snapshot = await query.get();
    //console.log(snapshot);
    snapshot.docs.map((doc) => ({
      id: doc.id,
    }));
    const idColono = snapshot.docChanges[0].doc.id;
    //console.log(idColono);
    this.usuariosCollection = this._afs.collection("usuario", (x) =>
      x.orderBy("nombre", "asc")
    );
    return await this.usuariosCollection.doc(idColono).valueChanges();
  }

  async getIdUsuarioCorreo(correo) {
    const query = this._afs
      .collection("usuario")
      .ref.where("correo", "==", correo);
    const snapshot = await query.get();
    snapshot.docs.map((doc) => ({
      id: doc.id,
    }));
    const idColono = snapshot.docChanges[0].doc.id;
    return idColono;
  }

  async getIdUsuarioCelular(celular) {
    const query = this._afs
      .collection("usuario")
      .ref.where("celular", "==", celular);
    const snapshot = await query.get();
    snapshot.docs.map((doc) => ({
      id: doc.id,
    }));
    if (snapshot.empty != true) {
      const idColono = snapshot.docChanges[0].doc.id;
      this.usuariosCollection = this._afs.collection("usuario", (x) =>
        x.orderBy("nombre", "asc")
      );
      return idColono;
    } else {
      return null;
    }
  }

  getColonosLibres(privada) {
    this.usuariosCollection = this._afs.collection("usuario", (ref) =>
      ref
        .where("privada", "==", privada)
        .where("tipoUsuario", "==", 5)
        .where("asignado", "==", false)
    );

    this.usuarios = this.usuariosCollection.snapshotChanges().map((changes) => {
      return changes.map((a) => {
        const data = a.payload.doc.data();
        data.id = a.payload.doc.id;
        return data;
      });
    });

    return this.usuarios;
  }

  getGuardiasLibres(privada) {
    this.usuariosCollection = this._afs.collection("usuario", (ref) =>
      ref
        .where("privada", "==", privada)
        .where("tipoUsuario", "==", 4)
        .where("idCaseta", "==", null)
    );

    this.usuarios = this.usuariosCollection.snapshotChanges().map((changes) => {
      return changes.map((a) => {
        const data = a.payload.doc.data();
        data.id = a.payload.doc.id;
        return data;
      });
    });

    return this.usuarios;
  }

  getUsuarios() {
    if (this.ag.sesion == true) {
      if (this.ag.tipo.tipoUsuario == "1" || this.ag.tipo.tipoUsuario == "2") {
        this.usuariosCollection = this._afs.collection("usuario", (x) =>
          x.orderBy("nombre", "asc")
        );
      } else {
        this.usuariosCollection = this._afs.collection("usuario", (ref) =>
          ref.where("privada", "==", this.ag.tipo.privada)
        );
      }
    } else {
      this.usuariosCollection = this._afs.collection("usuario", (x) =>
        x.orderBy("nombre", "asc")
      );
    }

    this.usuarios = this.usuariosCollection.snapshotChanges().map((changes) => {
      return changes.map((a) => {
        const data = a.payload.doc.data();
        data.id = a.payload.doc.id;
        return data;
      });
    });

    return this.usuarios;
  }

  getAll() {
    if (this.ag.sesion == true) {
      if (this.ag.tipo.tipoUsuario == "1" || this.ag.tipo.tipoUsuario == "2") {
        this.usuariosCollection = this._afs.collection("usuario", (x) =>
          x.orderBy("nombre", "asc")
        );
      } else {
        this.usuariosCollection = this._afs.collection("usuario", (ref) =>
          ref.where("privada", "==", this.ag.tipo.privada)
        );
      }
    } else {
      this.usuariosCollection = this._afs.collection("usuario", (x) =>
        x.orderBy("nombre", "asc")
      );
    }
    this.usuarios = this.usuariosCollection.snapshotChanges().map((changes) => {
      return changes.map((a) => {
        const data = a.payload.doc.data();
        data.id = a.payload.doc.id;
        return data;
      });
    });

    return this.usuarios;
  }

  getAdmitidos() {
    if (this.ag.sesion == true) {
      if (this.ag.tipo.tipoUsuario == "1" || this.ag.tipo.tipoUsuario == "2") {
        this.usuariosCollection = this._afs.collection("usuario", (x) =>
          x.orderBy("nombre", "asc").where("aprobacion", "==", true)
        );
      } else {
        this.usuariosCollection = this._afs.collection("usuario", (ref) =>
          ref
            .where("privada", "==", this.ag.tipo.privada)
            .where("aprobacion", "==", true)
        );
      }
    } else {
      this.usuariosCollection = this._afs.collection("usuario", (x) =>
        x.orderBy("nombre", "asc").where("aprobacion", "==", true)
      );
    }

    this.usuarios = this.usuariosCollection.snapshotChanges().map((changes) => {
      return changes.map((a) => {
        const data = a.payload.doc.data();
        data.id = a.payload.doc.id;
        return data;
      });
    });

    return this.usuarios;
  }

  getNoAdmitidos() {
    if (this.ag.sesion == true) {
      if (this.ag.tipo.tipoUsuario == "1" || this.ag.tipo.tipoUsuario == "2") {
        this.usuariosCollection = this._afs.collection("usuario", (x) =>
          x.orderBy("nombre", "asc").where("aprobacion", "==", false)
        );
      } else {
        this.usuariosCollection = this._afs.collection("usuario", (ref) =>
          ref
            .where("privada", "==", this.ag.tipo.privada)
            .where("aprobacion", "==", false)
        );
      }
    } else {
      this.usuariosCollection = this._afs.collection("usuario", (x) =>
        x.orderBy("nombre", "asc").where("aprobacion", "==", false)
      );
    }

    this.usuarios = this.usuariosCollection.snapshotChanges().map((changes) => {
      return changes.map((a) => {
        const data = a.payload.doc.data();
        data.id = a.payload.doc.id;
        return data;
      });
    });

    return this.usuarios;
  }

  getNoVerificados() {
    if (this.ag.sesion == true) {
      if (this.ag.tipo.tipoUsuario == "1" || this.ag.tipo.tipoUsuario == "2") {
        this.usuariosCollection = this._afs.collection("usuario", (x) =>
          x.orderBy("nombre", "asc").where("verificacion", "==", false)
        );
      } else {
        this.usuariosCollection = this._afs.collection("usuario", (ref) =>
          ref
            .where("privada", "==", this.ag.tipo.privada)
            .where("verificacion", "==", false)
        );
      }
    } else {
      this.usuariosCollection = this._afs.collection("usuario", (x) =>
        x.orderBy("nombre", "asc").where("verificacion", "==", false)
      );
    }

    this.usuarios = this.usuariosCollection.snapshotChanges().map((changes) => {
      return changes.map((a) => {
        const data = a.payload.doc.data();
        data.id = a.payload.doc.id;
        return data;
      });
    });

    return this.usuarios;
  }

  getNoAsignados() {
    if (this.ag.sesion == true) {
      if (this.ag.tipo.tipoUsuario == "1" || this.ag.tipo.tipoUsuario == "2") {
        this.usuariosCollection = this._afs.collection("usuario", (x) =>
          x.orderBy("nombre", "asc").where("asignado", "==", false)
        );
      } else {
        this.usuariosCollection = this._afs.collection("usuario", (ref) =>
          ref
            .where("privada", "==", this.ag.tipo.privada)
            .where("asignado", "==", false)
            .where("tipoUsuario", "==", 5)
        );
      }
    } else {
      this.usuariosCollection = this._afs.collection("usuario", (x) =>
        x.orderBy("nombre", "asc").where("asignado", "==", false)
      );
    }

    this.usuarios = this.usuariosCollection.snapshotChanges().map((changes) => {
      return changes.map((a) => {
        const data = a.payload.doc.data();
        data.id = a.payload.doc.id;
        return data;
      });
    });

    return this.usuarios;
  }

  getVerificados() {
    if (this.ag.sesion == true) {
      if (this.ag.tipo.tipoUsuario == "1" || this.ag.tipo.tipoUsuario == "2") {
        this.usuariosCollection = this._afs.collection("usuario", (x) =>
          x.orderBy("nombre", "asc").where("verificado", "==", true)
        );
      } else {
        this.usuariosCollection = this._afs.collection("usuario", (ref) =>
          ref
            .where("privada", "==", this.ag.tipo.privada)
            .where("verificado", "==", true)
        );
      }
    } else {
      this.usuariosCollection = this._afs.collection("usuario", (x) =>
        x.orderBy("nombre", "asc").where("verificado", "==", true)
      );
    }

    this.usuarios = this.usuariosCollection.snapshotChanges().map((changes) => {
      return changes.map((a) => {
        const data = a.payload.doc.data();
        data.id = a.payload.doc.id;
        return data;
      });
    });

    return this.usuarios;
  }

  getAsignados() {
    if (this.ag.sesion == true) {
      if (this.ag.tipo.tipoUsuario == "1" || this.ag.tipo.tipoUsuario == "2") {
        this.usuariosCollection = this._afs.collection("usuario", (x) =>
          x.orderBy("nombre", "asc").where("asignado", "==", true)
        );
      } else {
        this.usuariosCollection = this._afs.collection("usuario", (ref) =>
          ref
            .where("privada", "==", this.ag.tipo.privada)
            .where("asignado", "==", true)
        );
      }
    } else {
      this.usuariosCollection = this._afs.collection("usuario", (x) =>
        x.orderBy("nombre", "asc").where("asignado", "==", true)
      );
    }

    this.usuarios = this.usuariosCollection.snapshotChanges().map((changes) => {
      return changes.map((a) => {
        const data = a.payload.doc.data();
        data.id = a.payload.doc.id;
        return data;
      });
    });

    return this.usuarios;
  }

  getUsuario(idUsuario) {
    this.usuariosCollection = this._afs.collection("usuario", (x) =>
      x.orderBy("nombre", "asc")
    );
    return this.usuariosCollection.doc(idUsuario).valueChanges();
  }

  async guardar(usuario) {
    delete usuario.password;
    if (usuario.passwordConfirmada) delete usuario.passwordConfirmada;

    usuario.asignado = false;
    this.usuariosCollection = this._afs.collection("usuario", (x) =>
      x.orderBy("nombre", "asc")
    );

    let respuesta: any = await this.buscaRegistroMati(usuario);
    if (respuesta != null) {
      usuario.foto = respuesta.foto;
      usuario.mati = respuesta.documents;
      if (respuesta.identity.status == "verified") {
        usuario.verificado = true;
      }
    }

    let id = await this.usuariosCollection.add(usuario);
    if (usuario.opcion) {
      await this.ag.getAuth();
      await this.router.navigate(["/perfil"]);
    }
    return id.id;
  }

  get(id) {
    return this._afs
      .collection("usuario", (x) => x.orderBy("nombre", "asc"))
      .doc(id)
      .valueChanges();
  }

  eliminar(idUsuario: string) {
    this.bd.list("/usuario/" + idUsuario).remove();
  }

  async editar(idUsuario, usuario) {
    this.usuariosCollection = this._afs.collection("usuario", (x) =>
      x.orderBy("nombre", "asc")
    );
    await this.usuariosCollection.doc(idUsuario).update(usuario);
  }

  async buscaRegistroMati(usuario) {
    console.log(usuario);
    const query2 = this._afs
      .collection("verificaciones_mati")
      .ref.where("correo", "==", usuario.correo);
    let idMati = null;

    await query2.get().then(async (qs) => {
      qs.forEach(async (ds) => {
        if (ds.ref.id != null || ds.ref.id != undefined) {
          idMati = ds.ref.id;
        }
      });
    });

    if (idMati != null) {
      console.log(idMati);
      let verificacion = await this._afs
        .collection("verificaciones_mati")
        .doc(idMati)
        .valueChanges()
        .take(1)
        .toPromise();
      return verificacion;
    } else {
      return null;
    }
  }

  createSafrUser(data) {
    const url =
      "https://covi.real.com/people?insert=true&update=true&update-if-lower-quality=false&merge=false&regroup=false&detect-age=true&detect-gender=true&detect-sentiment=false&detect-occlusion=false&detect-mask=false&differentiate=false&similar_limit=0&linear-match=false&site=default&source=default&provide-face-id=true&min-cpq=-1&min-fsq=-1&min-fcq=-1&insert-profile=false&max-occlusion=-1&event=none&context=live&type=person&include-expired=false";
    const headers = {
      accept: "application/json;charset=UTF-8",
      "X-RPC-DIRECTORY": "main",
      "X-RPC-SITE": "main",
      "X-RPC-SOURCE": "main",
      "X-RPC-PERSON-FIRST-NAME": data.name,
      "X-RPC-PERSON-LAST-NAME": data.lastName,
      "X-RPC-EXTERNAL-ID": data.id,
      "X-RPC-AUTHORIZATION": "entrify2:Y57sVpNUFDjzs@e",
      "Content-Type": "image/jpeg",
    };
  }

  getMati(id) {
    const options = {
      method: "GET",
      headers: {
        accept: "application/json",
        authorization:
          "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGllbnQiOnsiX2lkIjoiNjA2Y2NiYmM1ODYzNjkwMDFiZWE5OGE4IiwibWVyY2hhbnQiOnsiX2lkIjoiNjA2Y2NiYmM1ODYzNjkwMDFiZWE5OGE2Iiwib3duZXIiOiI2MDZjY2JiYzU4NjM2OTAwMWJlYTk4YTQiLCJzdWJzY3JpcHRpb25TdGF0dXMiOnsidmFsdWUiOiJhY3RpdmUiLCJ1cGRhdGVkQXQiOiIyMDIxLTA0LTA2VDIxOjAwOjIzLjgzNFoifSwidGFncyI6WyJjYW4tdXNlLWFuYWx5dGljcy13aWRnZXQiLCJjYW4tdXNlLXJldXNhZ2UiLCJjYW4tc2VlLXByZW1pdW0taW5zaWdodHMiLCJjYW4tc2VlLXR0diJdfX0sInVzZXIiOnsiX2lkIjoiNjA2Y2NiYmM1ODYzNjkwMDFiZWE5OGE0In0sInNjb3BlIjoidmVyaWZpY2F0aW9uX2Zsb3cgaWRlbnRpdHk6cmVhZCB2ZXJpZmljYXRpb246cmVhZCIsImlhdCI6MTcxOTUzMzAyMiwiZXhwIjoxNzE5NTM2NjIyLCJpc3MiOiJtYXRpLmlvIn0.zkvRylKmMHJbUNpkLpt8VcjAYw5IlIeRx5yTio4uygU",
      },
    };

    return fetch("https://api.prod.metamap.com/v2/verifications/" + id, options)
      .then((response) => response.json())
      .then((response) => {
        return response;
      })
      .catch((err) => console.error(err));
  }

  getPhoto() {
    const options = {
      method: "GET",
      headers: {
        accept: "application/json",
        authorization:
          "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGllbnQiOnsiX2lkIjoiNjA2Y2NiYmM1ODYzNjkwMDFiZWE5OGE4IiwibWVyY2hhbnQiOnsiX2lkIjoiNjA2Y2NiYmM1ODYzNjkwMDFiZWE5OGE2Iiwib3duZXIiOiI2MDZjY2JiYzU4NjM2OTAwMWJlYTk4YTQiLCJzdWJzY3JpcHRpb25TdGF0dXMiOnsidmFsdWUiOiJhY3RpdmUiLCJ1cGRhdGVkQXQiOiIyMDIxLTA0LTA2VDIxOjAwOjIzLjgzNFoifSwidGFncyI6WyJjYW4tdXNlLWFuYWx5dGljcy13aWRnZXQiLCJjYW4tdXNlLXJldXNhZ2UiLCJjYW4tc2VlLXByZW1pdW0taW5zaWdodHMiLCJjYW4tc2VlLXR0diJdfX0sInVzZXIiOnsiX2lkIjoiNjA2Y2NiYmM1ODYzNjkwMDFiZWE5OGE0In0sInNjb3BlIjoidmVyaWZpY2F0aW9uX2Zsb3cgaWRlbnRpdHk6cmVhZCB2ZXJpZmljYXRpb246cmVhZCIsImlhdCI6MTcxOTU5NTUyMywiZXhwIjoxNzE5NTk5MTIzLCJpc3MiOiJtYXRpLmlvIn0.NCYQnF7wSPbzPJlSB3H_Bs8LZD2F5o78DUp10mZG1yg",
      },
    };

    return fetch(
      "https://media-cdn.prod.metamap.com/selfies/production/8dad63da-1888-4016-9387-eed803225d3f.jpeg?Expires=1719795613&Policy=eyJTdGF0ZW1lbnQiOlt7IlJlc291cmNlIjoiaHR0cHM6Ly9tZWRpYS1jZG4ucHJvZC5tZXRhbWFwLmNvbS9zZWxmaWVzL3Byb2R1Y3Rpb24vOGRhZDYzZGEtMTg4OC00MDE2LTkzODctZWVkODAzMjI1ZDNmLmpwZWciLCJDb25kaXRpb24iOnsiRGF0ZUxlc3NUaGFuIjp7IkFXUzpFcG9jaFRpbWUiOjE3MTk3OTU2MTN9fX1dfQ__&Signature=JRkK1kYQZCBs7XSATmF--nUXqsh7pGktfTgqlK9Kzt888VfBYL1RhbRhaj-pkj8mZmUfLwASpqSCXsrl~xvmqCVkDjA~z3qjlofsBhrmsaovenFz-e9K9YaYoIbbwDR6pU4t7xIf2P3vhH~O7yvPwHtdoJ2kVd0ySZg-03~GkWXPX9YM7HY7IJtFLECYcsEiuGjRJvuXOHfHvtFrySIPTBytKZvhTs6jFwnG-P-EEfZKj0fVOXgiSTuWJQx0B4rfuvxBO1BJA9E4hv-wxt0x1tsX6LYq4Iual4PfnHdxXDNGL-sX0tLqTMchG7Z2JoHQrdFGVirpcdYn3f-QPIHWXg__&Key-Pair-Id=K3UFJ5LH7J6562",
      options
    )
      .then((response) => response.json())
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async uploadUserToSafr(file, data) {
    try {
      // Get the file object from the image URL
      // Create FormData and append the image file and user data
      const formData = new FormData();
      formData.append("file", file);

      // Send the POST request to create the user
      const createResponse = await fetch(
        "https://covi.real.com/people?insert=true&update=true&update-if-lower-quality=false&merge=false&regroup=false&detect-age=true&detect-gender=true&detect-sentiment=false&detect-occlusion=false&detect-mask=false&differentiate=false&similar_limit=0&linear-match=false&site=default&source=default&provide-face-id=true&min-cpq=-1&min-fsq=-1&min-fcq=-1&insert-profile=false&max-occlusion=-1&event=none&context=live&type=person&include-expired=false",
        {
          method: "POST",
          headers: {
            accept: "application/json;charset=UTF-8",
            "X-RPC-DIRECTORY": "main",
            "X-RPC-SITE": "main",
            "X-RPC-SOURCE": "main",
            "X-RPC-PERSON-FIRST-NAME": "kevin",
            "X-RPC-PERSON-LAST-NAME": "han",
            "X-RPC-EXTERNAL-ID": "3",
            "X-RPC-AUTHORIZATION": "entrify2:Y57sVpNUFDjzs@e",
          },
        }
      );

      // Check if the creation was successful
      if (!createResponse.ok) throw new Error("Failed to create user");

      const result = await createResponse.json();
      console.log("User created successfully:", result);
    } catch (error) {
      console.log("There has been a problem with your fetch operation:", error);
    }
  }

  async getPerson() {
    const url = `https://covi.real.com/people/64604245-ee41-4981-9fcc-05d309a6545e`;

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          accept: "application/json;charset=UTF-8",
          "X-RPC-DIRECTORY": "main",
          "X-RPC-AUTHORIZATION": "entrify2:Y57sVpNUFDjzs@e",
        },
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
      console.log(response);

      const data = await response.json();
      console.log("User data:", data);
    } catch (error) {
      console.log("There was a problem with the fetch operation:", error);
    }
  }
}
