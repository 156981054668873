import { Injectable } from "@angular/core";
import {
  Router,
  RouterStateSnapshot,
  CanActivate,
  ActivatedRoute,
} from "@angular/router";
import { AngularFireAuth } from "angularfire2/auth";
import { AngularFireDatabase, AngularFireAction } from "angularfire2/database";
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
} from "angularfire2/firestore";
import "rxjs/add/operator/take";

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Response } from "@angular/http";
import { throwError, Observable } from "rxjs";
import { map, catchError } from "rxjs/operators";

@Injectable()
export class AuthGuardService {
  sesion = false;
  idUsuario;
  tipo: any;
  usuario: any;
  data = {};
  doc;
  idInvitacion = null;
  idPuerta = null;
  url;

  constructor(
    public afAuth: AngularFireAuth,
    private router: Router,
    private route: ActivatedRoute,
    public bd: AngularFirestore,
    private http: HttpClient
  ) {}

  /**
   * metodo de que permite editar un acceso
   * @param data correo para cambiar contraseña
   * @returns true o false
   **/
  sendPasswordResetEmail(data) {
    return this.afAuth.auth
      .sendPasswordResetEmail(data.correo)
      .then(function () {
        return true;
      })
      .catch(function (error) {
        return false;
      });
  }

  async getDatosUsuario(id) {
    console.log(id);
    let respuesta = await this.getUsuarioById(id);
    return this.tipo;
  }

  async getCaptchaResponse(captchaResponse) {
    const secretKey = "6LeXlKUZAAAAAMjylN63n1GocVv3nxLbxLARJT8F";
    const url =
      "https://www.google.com/recaptcha/api/siteverify?secret=" +
      secretKey +
      "&response=" +
      captchaResponse;
    const response = this.http.get(url).pipe(
      map((response: any) => response),
      catchError((error: any) =>
        throwError(error || { message: "Error del servidor" })
      )
    );
    return response.toPromise();
  }

  /**
   * metodo de que determina si se tiene una sesion activa
   **/
  async sesionActiva() {
    var id = await localStorage.getItem("id");
    //console.log(id);
    if (id != null) {
      this.router.navigate(["perfil"]);
    }
  }

  /**
   * metodo de obtiene los datos de usuario si esta autenticado
   **/
  async getAuth() {
    await this.afAuth.authState
      .take(1)
      .toPromise()
      .then(async (user) => {
        if (user) {
          if (user.emailVerified == false) {
            user.sendEmailVerification();
          }
          this.idUsuario = await this.getUsuario(user.email);
          this.sesion = true;
          return true;
        } else {
          this.sesion = false;
          this.idUsuario = {};
          this.tipo = {};
          this.usuario = {};
          return false;
        }
      });
    return false;
  }

  /**
   * metodo de obtiene los datos de usuario de la bd
   **/
  async getUsuario(correo) {
    const query = await this.bd
      .collection("usuario")
      .ref.where("correo", "==", correo);
    const snapshot = await query.get();
    await snapshot.docs.map((doc) => ({
      id: doc.id,
    }));
    this.idUsuario = snapshot.docChanges[0].doc.id;

    const usuario = await this.bd
      .collection("usuario")
      .doc(this.idUsuario)
      .valueChanges();
    await usuario
      .take(1)
      .toPromise()
      .then(async (x) => {
        this.sesion = true;
        this.tipo = x;
        this.tipo.id = this.idUsuario;
        await localStorage.setItem("nombre", this.tipo.nombre);
        await localStorage.setItem("id", this.tipo.id);
        await localStorage.setItem("verificado", this.tipo.verificado);
        if (this.tipo.tipoUsuario == 4) {
          await localStorage.setItem("nombrePrivada", this.tipo.nombrePrivada);
          await localStorage.setItem("privadaId", this.tipo.privada);
          if (this.tipo.idCaseta != null || this.tipo.idCaseta != undefined)
            await localStorage.setItem("idCaseta", this.tipo.idCaseta);
          this.router.navigate(["administracion/guardia"]);
        } else {
          this.router.navigate(["perfil"]);
        }
      });
  }

  async getUsuarioById(id) {
    const consulta = await this.bd.collection("usuario").doc(id).valueChanges();
    console.log(consulta);
    return consulta;
  }

  async getUsuarioByEmail(email) {
    const querySnapshot = await this.bd
      .collection("usuario")
      .ref.where("correo", "==", email)
      .limit(1)
      .get();

    if (querySnapshot.empty) {
      console.log("No matching documents.");
      return null;
    }

    let user = null;
    querySnapshot.forEach((doc) => {
      user = { id: doc.id, ...doc.data() };
    });
    return user;
  }

  /**
   * metodo de que permite hacer el login
   **/
  async login() {
    await this.afAuth.authState
      .take(1)
      .toPromise()
      .then((usuario) => {
        //console.log(usuario)
        if (usuario) {
          this.usuario.id = usuario.uid;
          this.idUsuario = usuario.uid;
        }
      });
  }

  /**
   * metodo de permite cerrar sesion
   **/
  logout() {
    this.sesion = false;
    this.idUsuario = {};
    this.tipo = {};
    this.usuario = {};
    localStorage.clear();
    this.afAuth.auth.signOut();
    this.router.navigate(["landing"]);
  }
}
