import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';

import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { MiInformacionComponent } from 'app/modulos/mi-informacion/mi-informacion.component';
import { VerificacionMatiComponent } from 'app/modulos/verificacion-mati/verificacion-mati.component';

import { ImagenesPerfilComponent } from 'app/modulos/imagenes-perfil/imagenes-perfil.component';
import { AuthGuardService } from 'app/servicios/auth-guard.service';
import { UsuarioService } from 'app/servicios/usuario.service';

@NgModule({
  schemas: [ CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    RouterModule,
    NgbModule,
    FormsModule,

  ],
  declarations: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    MiInformacionComponent,
    VerificacionMatiComponent,
    ImagenesPerfilComponent
  ],
  exports: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent
  ]
})
export class ComponentsModule { }
