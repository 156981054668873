import { Component, OnInit } from '@angular/core';
import { AuthGuardService } from './../../servicios/auth-guard.service'
import { UsuarioService } from './../../servicios/usuario.service'
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from 'angularfire2/storage';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap/modal/modal';
import { InvitacionService } from './../../servicios/invitacion.service';
import { CasasService } from './../../servicios/casas.service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap/modal/modal-ref';

@Component({
  selector: 'app-imagenes-perfil',
  templateUrl: './imagenes-perfil.component.html',
  styleUrls: ['./imagenes-perfil.component.scss']
})
export class ImagenesPerfilComponent implements OnInit {
  modalRef: NgbModalRef;
  onChange: any;
  fotoPerfil:string = '';
  fotoIdentificacion="";
  fotoIdentificacionTrasera="";
  usuario;
  imagen;
  link;
  identificacion;
  identificacionTrasera;
  band;
  band2;
  band3;
  habitante;
  habitantes$;
  perfilCarga;
  identificacionCarga;
  identificacionTraseraCarga;
  ref: AngularFireStorageReference;

  constructor(public ag: AuthGuardService,
    public invitacion: InvitacionService,
    private modalService: NgbModal,
    private usuarioService: UsuarioService,
    private casasService: CasasService,
    private sg: AngularFireStorage) { }

  /**
  * Metodo inicializiacion del componente
  **/
  async ngOnInit() {
    this.perfilCarga=false;
    this.identificacionCarga=false;
    await this.ag.getAuth();
    this.usuario = this.ag.tipo;
  }

  /**
  * Metodo que permite guardar una imagen
  * @param event imagen
  **/
  guardarImagen(event) {
    this.imagen = event.target.files[0];
    this.previzualizacionPerfil(this.imagen);

    // this.rotarImagen(event.target.files[0])
    this.band = true;
    this.perfilCarga=true;
  }

  /**
  * metodo que despliega un modal
  * @param content referencia al modal
  **/
  async openModal(content) {
    this.modalRef = this.modalService.open(content, { size: 'lg' , centered:true});
  }

  /**
  * Metodo muestra una visualizacion e la imagen seleccionada
  * @param file imagen
  **/
  previzualizacionPerfil(file: File) {
      let reader = new FileReader;
      // TODO: Define type of 'e'
      reader.onload = (e: any) => {
          // Simply set e.target.result as our <img> src in the layout
          this.fotoPerfil = e.target.result;
          this.onChange.emit(file);
      };
      reader.readAsDataURL(file);
  }

  /**
  * Metodo que permite guardar una imagen
  * @param event imagen
  **/
  guardarIdentificacion(event) {
    this.identificacion = event.target.files[0];
    this.previzualizacionIdentificacion(this.identificacion);
    this.band2 = true;
    this.identificacionCarga=true;
  }

  /**
  * Metodo muestra una visualizacion e la imagen seleccionada
  * @param file imagen
  **/
  previzualizacionIdentificacion(file: File) {
      let reader = new FileReader;
      // TODO: Define type of 'e'
      reader.onload = (e: any) => {
          // Simply set e.target.result as our <img> src in the layout
          this.fotoIdentificacion = e.target.result;
          this.onChange.emit(file);
      };
      reader.readAsDataURL(file);
  }


  /**
  * Metodo que permite guardar una imagen
  * @param event imagen
  **/
  guardarIdentificacionTrasera(event) {
    this.identificacionTrasera = event.target.files[0];
    this.previzualizacionIdentificacionTrasera(this.identificacionTrasera);
    this.band3 = true;
    this.identificacionTraseraCarga=true;
  }

  /**
  * Metodo muestra una visualizacion e la imagen seleccionada
  * @param file imagen
  **/
  previzualizacionIdentificacionTrasera(file: File) {
      let reader = new FileReader;
      // TODO: Define type of 'e'
      reader.onload = (e: any) => {
          // Simply set e.target.result as our <img> src in the layout
          this.fotoIdentificacionTrasera = e.target.result;
          this.onChange.emit(file);
      };
      reader.readAsDataURL(file);
  }

  /**
  *metodo que permite subir una imagen
  * @param usuario usuario al que se le anexa la imagen
  **/
  async subirImagen(usuario) {
    usuario = this.ag.tipo;
    this.ref = this.sg.ref('usuarios/' + this.ag.tipo.idFoto);
    await this.ref.delete();
    const randomId = Math.random().toString(36).substring(2);
    this.ref = this.sg.ref('usuarios/' + randomId);
    await this.ref.put(this.imagen).then().then(y => {
      usuario.idFoto = randomId;
      usuario.foto = y.downloadURL;
      this.usuarioService.editar(usuario.id, usuario);
    })
    this.perfilCarga=false;
  }
  /**
  *metodo que permite subir una imagen
  * @param usuario usuario al que se le anexa la imagen
  **/
  async subirIdentificacion(usuario) {
    usuario = this.ag.tipo;
    this.ref = this.sg.ref('identificacion/' + this.ag.tipo.idIdentificacion);
    await this.ref.delete();
    const randomId = Math.random().toString(36).substring(2);

    const foto = await this.sg.upload(`identificacion/${randomId}`, this.identificacion)
      .then(x => {
        return x.downloadURL;
      });
      usuario.identificacion = foto;
      usuario.idIdentificacion = randomId;
      await this.usuarioService.editar(usuario.id, usuario);
      this.identificacionCarga=false;
  }
  /**
  *metodo que permite subir una imagen
  * @param usuario usuario al que se le anexa la imagen
  **/
  async subirIdentificacionTrasera(usuario) {
    usuario = this.ag.tipo;
    this.ref = this.sg.ref('identificacionTrasera/' + this.ag.tipo.idIdentificacionTrasera);
    await this.ref.delete();
    const randomId = Math.random().toString(36).substring(2);

    const foto = await this.sg.upload(`identificacionTrasera/${randomId}`, this.identificacionTrasera)
      .then(x => {
        return x.downloadURL;
      });
      usuario.identificacionTrasera = foto;
      usuario.idIdentificacionTrasera = randomId;
      await this.usuarioService.editar(usuario.id, usuario);
      this.identificacionTraseraCarga=false;
  }

  /**
  * metodo que permite actualizar los campos de imagenes en la bd del usuario
  * @param usuario usuario al que se le anexa la imagen
  **/
  async actualizar(usuario) {
    await this.ag.getUsuario(usuario.correo);
  }
}
