import { Injectable } from '@angular/core';
import {Http, Headers} from '@angular/http';
import {FormGroup,FormControl,Validators} from '@angular/forms';

@Injectable()
export class VisitanteService {

  visitanteUrl:string="https://portero-a923a.firebaseio.com/visitante.json"

  constructor(private http:Http) { }

  nuevoVisitante(visitante: FormGroup){

    let body=JSON.stringify(visitante.value);
    let headers=new Headers({
      'Content-Type':'applications/json'
    });

    return this.http.post(this.visitanteUrl,body,{headers}).map(res=>{
      return res.json();
    });

  }

}
