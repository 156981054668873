import { JasperoAlertsModule } from '@jaspero/ng-alerts';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { QRCodeModule } from 'angularx-qrcode';
import { QrCodeModule } from 'ng-qrcode';
import { NgxQRCodeModule } from 'ngx-qrcode2';
import { DataTablesModule } from 'angular-datatables';
import { BrowserModule } from '@angular/platform-browser';

//firebase
import { AngularFireModule} from 'angularfire2';
import { AngularFireDatabaseModule, AngularFireList} from 'angularfire2/database';
import { AngularFirestoreModule } from 'angularfire2/firestore';
import { AngularFireStorageModule } from 'angularfire2/storage';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { environment } from '../environments/environment';


import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';

import { AppComponent } from './app.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';

//servicios
import {ClientsService} from './servicios/clients.service';
import {AuthGuardService} from './servicios/auth-guard.service';
import {PrivadaService} from './servicios/privada.service';
import {UsuarioService} from './servicios/usuario.service';
import {VisitanteService} from './servicios/visitante.service';
import {InvitacionService} from './servicios/invitacion.service';
import {BitacoraService} from './servicios/bitacora.service';
import {CalleService} from './servicios/calle.service';
import {UltimoAccesoService} from './servicios/ultimo-acceso.service';
import {AccesoService} from './servicios/acceso.service';
import { Ng2ImgMaxModule } from 'ng2-img-max';
import { DeviceDetectorModule } from 'ngx-device-detector';

import { ListaInvitadosService } from './servicios/lista-invitados.service';
import { InvitacionesService } from './servicios/invitaciones.service';


@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    HttpClientModule,
    ComponentsModule,
    RouterModule,
    AppRoutingModule,
    NgbModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features,
    AngularFireStorageModule, // imports firebase/storage only needed for storage features
    FormsModule,
    BrowserModule,
    DataTablesModule,
    NgxQRCodeModule,
    QRCodeModule,
    QrCodeModule,
    BrowserAnimationsModule,
    Ng2ImgMaxModule,
    JasperoAlertsModule.forRoot(),
    DeviceDetectorModule.forRoot()
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent
  ],
  providers: [
    ClientsService,
    AuthGuardService,
    PrivadaService,
    UsuarioService,
    VisitanteService,
    InvitacionService,
    BitacoraService,
    CalleService,
    UltimoAccesoService,
    AccesoService,
    ListaInvitadosService,
    InvitacionesService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
