// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {

  //NOK
  production: false,
    firebase: {
      apiKey: "AIzaSyCOaoL43HTewt72rEst3q8V-H2acYLuChU",
      authDomain: "nok-2020.firebaseapp.com",
      databaseURL: "https://nok-2020.firebaseio.com",
      projectId: "nok-2020",
      storageBucket: "nok-2020.appspot.com",
      messagingSenderId: "216171080789",
      appId: "1:216171080789:web:cbee0a349432131777b8dc",
      measurementId: "G-DMN9WP35N2"
    }
  //PORTERODEV
//   production: false,
//   firebase: {
//     apiKey: "AIzaSyCv57mIsUJlgLbmHhAXK2QtVELEZdEI3mA",
//     authDomain: "porterodev.firebaseapp.com",
//     databaseURL: "https://porterodev.firebaseio.com",
//     projectId: "porterodev",
//     storageBucket: "porterodev.appspot.com",
//     messagingSenderId: "749632355341"
//   }
};
