import { Injectable } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';

@Injectable({
  providedIn: 'root'
})
export class ClientsService {

  constructor(private db: AngularFireDatabase) { }

  getAll() {
    return this.db.list('/clientes').snapshotChanges();
  }

  get(idCliente) {
    return this.db.object('/clientes/' + idCliente).snapshotChanges();
  }

  create(cliente): boolean {
    this.db.list('/clientes').push(cliente);
    return true;
  }

  update(idCliente, cliente) {
    return this.db.object('/clientes/' + idCliente)
      .update(cliente);
  }
}
