import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from 'angularfire2/firestore';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';

@Injectable()
export class ListaInvitadosService {
    public static readonly collection = 'lista-invitados';

    constructor(
        private firestore:AngularFirestore
    ){}

    public getContactList(id: any){
        return this.firestore
        .collection(ListaInvitadosService.collection, ref => ref
            .where("idColono", "==", id)
        ).snapshotChanges().map(changes => {
            return changes.map(a => {
            const data = a.payload.doc.data();
            data.id = a.payload.doc.id;
            return data;
            });
        });
    }

    public async saveGuest(guest: any){
      try{
        await this.firestore.collection(ListaInvitadosService.collection).add(guest);
        return true;
      }catch(error){
        console.log(error);
        return false;
      }
    }

    public async existsByCel(celular: any){
        let guest: any;
        
        try {
          guest = await this.firestore
          .collection(ListaInvitadosService.collection, x => x
            .where('celular', '==', celular)
          )
          .valueChanges()
          .take(1)
          .toPromise();
        } catch(error) {
            guest = null;
        }
        return guest;
    }

    public async existsByEmail(email: any){
        let guest: any;

        try {
          guest = await this.firestore
          .collection(ListaInvitadosService.collection, x => x
            .where('email', '==', email)
          )
          .valueChanges()
          .take(1)
          .toPromise();
        } catch(error) {
          guest = null;
        }
        return guest;
    }
}
