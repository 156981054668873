import { Injectable } from '@angular/core';
import {Http, Headers} from '@angular/http';
import {FormGroup,FormControl,Validators} from '@angular/forms';
import { AngularFireDatabase, AngularFireAction } from 'angularfire2/database';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from 'angularfire2/firestore';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import { AuthGuardService } from './auth-guard.service';
import { UsuarioService } from './usuario.service';

@Injectable({
  providedIn: 'root'
})
export class CasasService {
  privadasCollection;
  privada;
  privadas;
  casasCollection;
  casas;
  detalleCasaCollection;
  habitantes;

  constructor(private _afs:AngularFirestore,private ag:AuthGuardService,private usuarioService:UsuarioService) {
  }

  /**
  * metodo que obtiene los habitantes de una casa
  * @param idCasa identificador de casa
  * @returns habitantes de una casa
  **/
  getHabitantes(idCasa){
    this.detalleCasaCollection = this._afs.collection('detalleCasa',x => x.where('idCasa','==',idCasa));
    this.habitantes = this.detalleCasaCollection.snapshotChanges().map(changes => {
      return changes.map(a => {
        const data = a.payload.doc.data();
        data.id = a.payload.doc.id;
        return data;
      });
    });
    return this.habitantes;
  }

  /**
  * metodo que obtiene las casas de una privada
  * @param nombrePrivada nombre de la privada
  * @returns casas dentro de una privada
  **/
  getCasasPrivada(nombrePrivada){
    return this.casasCollection = this._afs.collection('casas', x => x.orderBy('numero', 'asc').where('nombrePrivada','==',nombrePrivada)).snapshotChanges().map(changes => {
      return changes.map(a => {
        const data = a.payload.doc.data();
        data.id = a.payload.doc.id;
        return data;
      });
    });;
  }

  /**
  * metodo que obtiene las casas de una privada
  * @param nombrePrivada nombre de la privada
  * @returns casas dentro de una privada
  **/
  getCasasIdCalle(idCalle){
    return this.casasCollection = this._afs.collection('casas', x => x.orderBy('numero', 'asc').where('idCalle','==',idCalle)).snapshotChanges().map(changes => {
      return changes.map(a => {
        const data = a.payload.doc.data();
        data.id = a.payload.doc.id;
        return data;
      });
    });;
  }

  /**
  * metodo que obtiene las casas de una privada
  * @returns casas dentro de una privada
  **/
  getCasas(){
    this.casasCollection = this._afs.collection('casas',ref => ref.where("idPrivada","==",this.ag.tipo.privada));
    this.casas = this.casasCollection.snapshotChanges().map(changes => {
      return changes.map(a => {
        const data = a.payload.doc.data();
        data.id = a.payload.doc.id;
        return data;
        
      });
    });
    return this.casas;
  }
  
  /**
  * metodo que obtiene una casa
  * @param id identificador de casa
  * @returns casa
  **/
  getCasa(id){
    this.casasCollection = this._afs.collection('casas');
    return this.casasCollection.doc(id).valueChanges();
  }

  /**
  * metodo que determina si una casa tiene un responsable
  * @param idCasa identificador de una casa
  * @returns true o false
  **/
  async existeResponsable(idCasa){
    let resultado;
    await this._afs.collection('casas').doc(idCasa)
    .valueChanges()
    .take(1)
    .toPromise()
    .then(y=>{
      resultado=y;
    })
    if(resultado.responsable)
      return true;
    else
      return false;
  }

  /**
  * metodo que verifica si existe una casa
  * @param casa objeto casa con dataos
  * @returns true o false
  **/
  async existeCasa(casa){
    //console.log(casa)
    let resultado;
    resultado = await this._afs.collection('casas',ref=>ref.where('idCalle','==',casa.idCalle)
    .where('numero','==',casa.numero))
    .valueChanges()
    .take(1)
    .toPromise();

    if(resultado.length>0)
      return true;
    else
      return false;
  }

  /**
  * metodo guarda un colono como habitante de una casa
  * @param idCasa identificador de la casa,
  * @param colono informacion del habitante
  **/
  async registraColono(idCasa,colono)
  {
    this.casasCollection = this._afs.collection('detalleCasa');
    colono.idCasa=idCasa;
    let resultado;
    await this.usuarioService.getUsuario(colono.colono).take(1)
    .toPromise()
    .then(async (res) =>
    {
      resultado=res;
      resultado.asignado=true;
      resultado.idCasa=idCasa;
      resultado.aprobacion=true;
      resultado.activo=true;
    });
    await this.usuarioService.editar(colono.colono,resultado);
    colono.nombreColono=resultado.nombre +' '+resultado.apellidoPaterno+' '+resultado.apellidoMaterno;
    colono.celular=resultado.celular;
    colono.direccion=resultado.direccion;
    colono.correo=resultado.correo;
    await this.casasCollection.add(colono);
  }

  /**
  * metodo que guarda una casa
  * @param casa objeto casa
  **/
  guardar(casa) {
    casa.idPrivada=this.ag.tipo.privada;
    casa.nombrePrivada=this.ag.tipo.nombrePrivada;
    this.casasCollection = this._afs.collection('casas');
    this.casasCollection.add(casa);
  }

  /**
  * metodo que elimina un habitante de una casa
  * @param idDetalle identificador del habitante a borrar
  **/
  async eliminaHabitante(idDetalle)
  {
    let idColono;
    let resultado;
    this.detalleCasaCollection = this._afs.collection('detalleCasa');
    let habitante=await this.detalleCasaCollection.doc(idDetalle).valueChanges()
    .take(1).toPromise().then(async (res) =>
    {
      idColono=res.colono;
    });


    await this.usuarioService.getUsuario(idColono).take(1)
    .toPromise()
    .then(async (res) =>
    {
      resultado=res;
      resultado.asignado=false;
      resultado.idCasa=null;
      resultado.aprobacion=false;
      resultado.activo=true;
    });
    await this.usuarioService.editar(idColono,resultado);
    this.detalleCasaCollection.doc(idDetalle).delete();
  }

  /**
  * metodo que edita la informacion de una casa
  * @param idCasa identificador de la casas
  * @param casa objeto casa
  **/
  editar(idCasa, casa) {
    this.casasCollection = this._afs.collection('casas');
    this.casasCollection.doc(idCasa).update(casa);
  }

  /**
  * metodo que obtiene la direccion de un colono
  * @param idColono identificador del colono
  * @returns detalle del colono
  **/
  getDireccion(idColono){
    return this._afs.collection('detalleCasa',ref=>ref.where('colono','==',idColono)).valueChanges();
  }
}
