import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from 'angularfire2/firestore';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subscription } from 'rxjs/Subscription';
import { UsuarioService } from './usuario.service';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';

@Injectable()
export class InvitacionService {
  invitaciones;
  collection;

  constructor(public bd: AngularFirestore,public usuarioService:UsuarioService) {
    this.collection=this.bd.collection('invitacion');
    // this.invitaciones = this.collection.snapshotChanges().map(changes => {
    //   return changes.map(a => {
    //     const data = a.payload.doc.data();
    //     data.id = a.payload.doc.id;
    //     return data;
    //   });
    // });
  }

  getInvitadosAdentro(privada)
  {
    console.log("adentro",privada)
    // const query2 = this.bd.collection('invitacion',ref => ref.where('autoInvitacion','==',false))
    const query2 = this.bd.collection('invitacion', ref => ref
    .where('idPrivada','==',privada)
    .where('estatus','==','Adentro'))
    .snapshotChanges().map(changes => {
      return changes.map(a => {
        const data = a.payload.doc.data();
        data.id = a.payload.doc.id;
        return data;
      });
    });
    return query2;
  }
  getInvitdosAfuera(privada){
    console.log("afuera")
    var d = new Date();
    let dia= d.getDate()
    let mes=(d.getMonth()+1)
    let anio=d.getFullYear();
    console.log("dia",dia,mes,anio)
    const query2 = this.bd.collection('invitacion', ref => ref

    .where('fecha.dia','==',dia)
    .where('fecha.mes','==',mes)
    .where('fecha.anio','==',anio)
    .where('idPrivada','==',privada)
    .where('estatus','==','Salio'))
    .snapshotChanges().map(changes => {
      return changes.map(a => {
        const data = a.payload.doc.data();
        data.id = a.payload.doc.id;
        return data;
      });
    });
    return query2;
  }

  /**
  * metodo que permite modificar las invitaciones cuando un usuario en preregistro
  * se registra
  * @param celular celular para buscar las invitaciones que tengan este numero
  **/
  actualizaInvitaciones(celular){
    return this.bd.collection('invitacion', ref => ref.where("celularInvitado","==",celular).orderBy("status","asc").orderBy("dp.year","desc").orderBy("dp.month","desc").orderBy("dp.day","desc"))
    .snapshotChanges().map(changes => {
      return changes.map(a => {
        const data = a.payload.doc.data();
        data.id = a.payload.doc.id;
        return data;
      });
    });
  }

  /**
  * metodo que obtiene las invitaciones de una privada con un codigo de verificacion
  * @param idPrivada identificador de privada
  * @param codigo codigo de invitacion
  * @returns invitaciones
  **/
  getInvitacionCodigo(idPrivada,codigo){
    codigo= parseInt(codigo,10);
    //console.log(idPrivada,codigo);
    return this.bd.collection('invitacion', ref => ref.where("idPrivada","==",idPrivada).where("codigo","==",codigo).where("estatus","==","Pendiente").orderBy("fecha.anio","desc").orderBy("fecha.mes","desc").orderBy("fecha.dia","desc"))
    .snapshotChanges().map(changes => {
      return changes.map(a => {
        const data = a.payload.doc.data();
        data.id = a.payload.doc.id;
        return data;
      });
    });
  }

  /**
  * metodo que obtiene una invitacion en especifico
  * @param id identificador de invitacion
  * @returns habitantes de una casa
  **/
  getInvitacion(id){
    return  this.collection.doc(id).valueChanges();
  }
  /**
  * metodo que permite la modificacion de una invitacion
  * @param id identificador de la invitacion
  * @param invitacion invitacion ya modificada
  * @returns habitantes de una casa
  **/
  editar(id,invitacion) {
    this.collection.doc(id).update(invitacion);
  }

  /**
  * metodo que verifica si existe una invitacion para un usuario
  * @param correo correo para buscar la invitacion
  * @returns true o false
  **/
  async existeInvitado(correo){
    let resultado;
    await this.bd.collection('usuario',x=>x.where('correo','==',correo))
    .valueChanges()
    .take(1)
    .toPromise()
    .then(y=>{
      if(y.length==0)
      {
        resultado=false;
      }
      else{
        resultado=true;
      }
    })
    return resultado

  }

  /**
  * metodo que guarda una invitacion
  * @param invitacion objeto con la informacion de la invitacion
  **/
  async guardar(invitacion) {
    //console.log(invitacion);
    return this.bd.collection('invitacion').add(invitacion);
  }

  /**
  * metodo que guarda un preregistro
  * @param invitacion informacion del preregistro
  **/
  async guardarPreRegistro(invitacion){
    //console.log(invitacion)
    return this.bd.collection('invitacion').add(invitacion);
  }


  /**
  * metodo que obtiene la autoinvitacion
  * @param invitacion informacion de la invitacion
  **/
  async acceso(invitacion) {
    const query3 = this.bd.collection('invitacion').ref
    .where('idColono', '==',invitacion.idColono)
    .where('idInvitado','==',invitacion.idInvitado)
    .where('status', '==', 'Colono');
    const snapshot = await query3.get();
     snapshot.docs.map(doc=>({
       id:doc.id
      }))

    if(snapshot.docChanges[0])
    {
      await this.collection.doc(snapshot.docs[0].id).delete();
    }
    return await this.collection.add(invitacion);
  }

  /**
  * metodo que obtiene las invitaciones de un usuario
  * @param id identificador de usuario
  * @returns invitaciones
  **/
  getInvitacionesUsuario(id)
  {
    return this.bd.collection('invitacion', ref => ref.where("idInvitado","==",id).orderBy("status","asc").orderBy("dp.year","desc").orderBy("dp.month","desc").orderBy("dp.day","desc"))
    .snapshotChanges().map(changes => {
      return changes.map(a => {
        const data = a.payload.doc.data();
        data.id = a.payload.doc.id;
        return data;
      });
    });
  }

  /**
  * metodo que obtiene las invitaciones enviadas por un usuario
  * @param id identificador de usuario
  * @returns invitaciones
  **/
  getInvitacionesEnviadasUsuario(id)
  {
    return this.bd.collection('invitacion', ref => ref.where("idColono","==",id).orderBy("status","asc").orderBy("dp.year","desc").orderBy("dp.month","desc").orderBy("dp.day","desc"))
    .snapshotChanges().map(changes => {
      return changes.map(a => {
        const data = a.payload.doc.data();
        data.id = a.payload.doc.id;
        return data;
      });
    });
  }

  /**
  * metodo que obtiene los invitados dentro de una privada
  * @param nombrePrivada nombre de la privada
  * @returns invitaciones
  **/
  getInvitadosDentro(nombrePrivada)
  {
    const query2 = this.bd.collection('invitacion', ref => ref.where("status","==","Entro").where('nombrePrivada','==',nombrePrivada))
    .snapshotChanges().map(changes => {
      return changes.map(a => {
        const data = a.payload.doc.data();
        data.id = a.payload.doc.id;
        return data;
      });
    });
    return query2;
  }

  /**
  * metodo que obtiene las invitaciones enviadas por un usuario
  * @param id identificador de usuario
  * @returns invitaciones
  **/
  async getInvitacionesEnviadas(id)
  {
    const query2 = await this.bd.collection('invitacion', ref => ref.where("idColono","==",id).orderBy("status","asc").orderBy("dp.year","desc").orderBy("dp.month","desc").orderBy("dp.day","desc"))
    .snapshotChanges().map(changes => {
      return changes.map(a => {
        const data = a.payload.doc.data();
        data.id = a.payload.doc.id;
        return data;
      });
    });
    return query2;
  }

  /**
  * metodo que obtiene las invitaciones programadas para una privada
  * @param id identificador de privada
  * @returns invitaciones
  **/
  async getInvitacionesProgramadas(id)
  {
    let currentDate = new Date();
    let day:any = currentDate.getDate();
    let month:any = currentDate.getMonth() + 1;
    let year:any = currentDate.getFullYear();

    const query2 = await this.bd.collection('invitacion', ref => ref.where("privada","==",id).where("dp.day","==",day).where("dp.month","==",month).where("dp.year","==",year))
    .snapshotChanges().map(changes => {
      return changes.map(a => {
        const data = a.payload.doc.data();
        data.id = a.payload.doc.id;
        return data;
      });
    });
    return query2;
  }

  /**
  * metodo que obtiene lectores de una privada
  * @param privada privada
  * @returns lectores
  **/
  getLectores(privada){
    const query = this.bd.collection('lectores', ref => ref.where("privada","==",privada))
    .snapshotChanges().map(changes => {
      return changes.map(a => {
        const data = a.payload.doc.data();
        data.id = a.payload.doc.id;
        return data;
      });
    });
    return query;
  }

  /**
  * Metodo que permite eliminar una invitacione
  * @params id identificador de la invitacion a eliminar
  **/
  eliminar(id){
    this.bd.collection('invitacion').doc(id).delete();
  }

  /**
  * Permite Que un colono pueda desactivar una invitacion
  **/
  async desactivar(id){
    let invitacion=await this.collection.doc(id).valueChanges().take(1).toPromise();
    //console.log(invitacion);
    invitacion.desactivado=true;
    //console.log(invitacion);
    await this.collection.doc(id).update(invitacion);
  }

  /**
  * Permite Que un colono pueda activar una invitacion
  **/
  async activar(id){
    let invitacion=await this.collection.doc(id).valueChanges().take(1).toPromise();
    //console.log(invitacion);
    invitacion.desactivado=false;
    //console.log(invitacion);
    await this.collection.doc(id).update(invitacion);
  }


}
