import { Injectable } from "@angular/core";
import { Http, Headers } from "@angular/http";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { AngularFireDatabase, AngularFireAction } from "angularfire2/database";
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
} from "angularfire2/firestore";
import { Observable } from "rxjs/Observable";
import { BehaviorSubject } from "rxjs/BehaviorSubject";
import { Subscription } from "rxjs/Subscription";
import "rxjs/add/operator/switchMap";
import "rxjs/add/operator/map";
import { AuthGuardService } from "./auth-guard.service";
import { UsuarioService } from "./usuario.service";

@Injectable({
  providedIn: "root",
})
export class UltimoAccesoService {
  usuario;
  usuarios;
  usuariosCollection;
  constructor(
    private _afs: AngularFirestore,
    private ag: AuthGuardService,
    private usuarioService: UsuarioService
  ) {}

  getUltimoAcceso(nombrePrivada, idCaseta) {
    console.log(nombrePrivada, idCaseta);

    this.usuariosCollection = this._afs.collection("ultimoAcceso", (ref) =>
      ref
        .where("nombrePrivada", "==", nombrePrivada)
        .where("idCaseta", "==", idCaseta)
        .orderBy("createdAt", "desc")
        .limit(1)
    );
    this.usuarios = this.usuariosCollection.snapshotChanges().map((changes) => {
      return changes.map((a) => {
        const data = a.payload.doc.data();
        console.log(data);
        data.id = a.payload.doc.id;
        return data;
      });
    });
    return this.usuarios;
  }

  async guardar(acceso, nombrePrivada) {
    const query2 = this._afs
      .collection("ultimoAcceso")
      .ref.where("nombrePrivada", "==", nombrePrivada);
    const snapshot2 = await query2.get();
    snapshot2.docs.map((doc) => ({
      id: doc.id,
    }));
    if (snapshot2.docChanges.length > 0) {
      const idUltimoAcceso = snapshot2.docChanges[0].doc.id;
      if (idUltimoAcceso != null || idUltimoAcceso != undefined)
        this._afs.collection("ultimoAcceso").doc(idUltimoAcceso).delete();
    }

    this._afs.collection("ultimoAcceso").add(acceso);
  }
}
