import { Component, OnInit } from '@angular/core';
import { AuthGuardService } from 'app/servicios/auth-guard.service';
import { UsuarioService } from 'app/servicios/usuario.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap/modal/modal';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap/modal/modal-ref';

@Component({
  selector: 'app-mi-informacion',
  templateUrl: './mi-informacion.component.html',
  styleUrls: ['./mi-informacion.component.scss']
})
export class MiInformacionComponent implements OnInit {
  banderaCelular=false;
  objeto;
  modalRef: NgbModalRef;

  constructor(
    private ag:AuthGuardService,
    private usuarioService:UsuarioService,
    private modalService:NgbModal) { }

  /**
  * Metodo de inicializacion del componente
  **/
  ngOnInit() {
    this.objeto=this.ag.tipo;
  }

  /**
  * Metodo verifica si existe el numero celular
  *
  **/
  async existeCelular(celular){
    let respuesta=await this.usuarioService.existeCelular(celular)
    if(this.ag.tipo.celular!=celular)
    {
      this.banderaCelular=respuesta;
    }
  }

  /**
  * Metodo permite modificar los datos de un usuario
  * @param usuario objeto con los datos del usuario
  **/
  async editar(usuario){

      this.objeto.nombre=usuario.nombre;
      this.objeto.apellidoPaterno=usuario.apellidoPaterno;
      this.objeto.apellidoMaterno=usuario.apellidoMaterno;
      this.objeto.telefono=usuario.telefono;
      this.objeto.tipoUsuario=parseInt(this.objeto.tipoUsuario,10);
      await this.usuarioService.editar(this.objeto.id,this.objeto);
      await this.ag.getAuth();
      await this.modalRef.close();
  }

  /**
  * Metodo despliega un modal
  **/
  async openModal(content) {
    this.modalRef = this.modalService.open(content, { size: 'sm' , centered:true});
  }
}
