import { Injectable } from '@angular/core';
import {Http, Headers} from '@angular/http';
import {FormGroup,FormControl,Validators} from '@angular/forms';
import { AngularFireDatabase, AngularFireAction } from 'angularfire2/database';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from 'angularfire2/firestore';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';


@Injectable({
  providedIn: 'root'
})
export class AccesoService {
  accesoCollection;
  acceso;

  constructor(private bd:AngularFirestore) { }

  /**
  * Metodo que almacena un acceso en la bd
  * @param objeto acceso
  **/
  guardar(objeto){
    //console.log('Guardando acceso', objeto)
    this.accesoCollection = this.bd.collection('acceso');
    this.accesoCollection.add(objeto);
  }

  /**
  * metodo de que obtiene los invitados dentro de la privada
  * @param nomre privada acceso
  * @returns todos los invitados dentro de la privada
  **/
  getInvitadosDentro(privada)
  {
    const query2 = this.bd.collection('acceso', ref => ref.where("horaSalida","==",null).where('autoInvitacion','==',false).where('privada','==',privada))
    .snapshotChanges().map(changes => {
      return changes.map(a => {
        const data = a.payload.doc.data();
        data.id = a.payload.doc.id;
        return data;
      });
    });
    return query2; 
  }
  getInvitdosAfuera(privada){
    var d = new Date();
    let dia= d.getDate()+'/'+(d.getMonth()+1)+'/'+d.getFullYear();
    console.log(dia);
    const query2 = this.bd.collection('acceso', ref => ref.where("horaSalida",'>=',0).where('autoInvitacion','==',false).where('dia','==',dia).where('privada','==',privada))
    .snapshotChanges().map(changes => {
      return changes.map(a => {
        const data = a.payload.doc.data();
        data.id = a.payload.doc.id;
        return data;
      });
    });
    return query2;
  }

  /**
  * metodo de que obtiene un acceso
  * @param idAcceso identificador de acceso
  * @returns acceso
  **/
  getAcceso(idAcceso)
  {
    this.accesoCollection=this.bd.collection('acceso');
    return  this.accesoCollection.doc(idAcceso).valueChanges();
  }

  /**
  * metodo de que permite editar un acceso
  * @param acceso privada acceso
  * @param id identificador de acceso
  **/
  editar(id,acceso) {
    this.accesoCollection=this.bd.collection('acceso');
    this.accesoCollection.doc(id).update(acceso);
  }
}
