import { Injectable } from "@angular/core";
import { AngularFireDatabase, AngularFireList } from "angularfire2/database";
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
} from "angularfire2/firestore";
import "rxjs/add/operator/switchMap";
import "rxjs/add/operator/map";

@Injectable()
export class InvitacionesService {
  public static readonly collection = "invitacion";

  constructor(private firestore: AngularFirestore) {}

  public async save(inv: any) {
    try {
      await this.firestore.collection(InvitacionesService.collection).add(inv);
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  public async delete(id: any) {
    try {
      await this.firestore
        .collection(InvitacionesService.collection)
        .doc(id)
        .delete();
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  public getAll() {
    return this.firestore.collection(InvitacionesService.collection);
  }

  public async getById(id: any) {
    return this.firestore
      .collection(InvitacionesService.collection)
      .doc(id)
      .valueChanges();
  }

  /* TODO create indexes
    .orderBy("status","asc")
    .orderBy("fecha.año","desc")
    .orderBy("fecha.mes","desc")
    .orderBy("fecha.dia","desc")
    */
  public getAllSentById(id: any) {
    return this.firestore
      .collection(InvitacionesService.collection, (ref) =>
        ref.where("idColono", "==", id).orderBy("createdAt", "desc")
      )
      .snapshotChanges()
      .map((changes) => {
        return changes.map((a) => {
          const data = a.payload.doc.data();
          data.id = a.payload.doc.id;
          return data;
        });
      });
  }

  public getAllReceivedById(id: any) {
    return this.firestore
      .collection(InvitacionesService.collection, (ref) =>
        ref.where("idInvitado", "==", id).orderBy("createdAt", "desc")
      )
      .snapshotChanges()
      .map((changes) => {
        return changes.map((a) => {
          const data = a.payload.doc.data();
          data.id = a.payload.doc.id;
          return data;
        });
      });
  }
}
