// Angular imports
import { Injectable } from '@angular/core';
import {Http, Headers} from '@angular/http';
import {FormGroup,FormControl,Validators} from '@angular/forms';


// Imported modules
import { AngularFireDatabase, AngularFireAction } from 'angularfire2/database';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from 'angularfire2/firestore';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';

//Services
import { AuthGuardService } from './auth-guard.service';

//Modelos
import {Privada} from './../modelos/privada';

@Injectable()
export class PrivadaService {

  privadasCollection;
  privadas;
  usuarios={};


  constructor(private _afs:AngularFirestore,private ag:AuthGuardService) {
    this.getUsuario();
 }

    /**
    * metodo que obtiene los usuarios que viven en una privada
    * @returns usuarios
    **/
    getUsuario(){
      if(this.ag.sesion==true)
      {
         if(this.ag.tipo.tipoUsuario=="1"||this.ag.tipo.tipoUsuario=="2")
         {
            this.privadasCollection = this._afs.collection('privada', x => x.orderBy('nombre', 'asc'));
            this.privadas = this.privadasCollection.snapshotChanges()
            .map(changes => {
              return changes.map(a => {
                const data = a.payload.doc.data() as Privada;
                data.id = a.payload.doc.id;
                return data;
              });
            });
          }

         else{
           if(this.ag.tipo.tipoUsuario!="6")
           {
             this.privadasCollection = this._afs.collection('privada',x => x.where('nombre','==',this.ag.tipo.nombrePrivada));
             this.privadas = this.privadasCollection.snapshotChanges()
             .map(changes => {
               return changes.map(a => {
                 const data = a.payload.doc.data() as Privada;
                 data.id = a.payload.doc.id;
                 return data;
               });
             });
           }

          }
        }
      else{
        this.privadasCollection = this._afs.collection('privada', x => x.orderBy('nombre', 'asc'));
        this.privadas = this.privadasCollection.snapshotChanges()
        .map(changes => {
          return changes.map(a => {
            const data = a.payload.doc.data() as Privada;
            data.id = a.payload.doc.id;
            return data;
          });
        });
      }
    }

    /**
    *
    **/
    async existePrivada(nombre){
      let resultado;
      await this._afs.collection('privada',x=>x.where('nombre','==',nombre))
      .valueChanges()
      .take(1)
      .toPromise()
      .then(y=>{
        if(y.length==0)
        {
          resultado=false;
        }
        else{
          resultado=true;
        }
      })
      return resultado;
    }

    /**
    * metodo que obtiene las privadas
    * @returns privadas
    **/
    getPrivadas() {
      if(this.ag.sesion==true)
      {
         if(this.ag.tipo.tipoUsuario=="1"||this.ag.tipo.tipoUsuario=="2")
         {
            this.privadasCollection = this._afs.collection('privada', x => x.orderBy('nombre', 'asc'));
            this.privadas = this.privadasCollection.snapshotChanges()
            .map(changes => {
              return changes.map(a => {
                const data = a.payload.doc.data();
                data.id = a.payload.doc.id;
                return data;
              });
            });
          }

         else{
           if (this.ag.tipo.tipoUsuario=="3"||this.ag.tipo.tipoUsuario=="4") {
             this.privadasCollection = this._afs.collection('privada',x => x.where('nombre','==',this.ag.tipo.nombrePrivada));
             this.privadas = this.privadasCollection.snapshotChanges()
             .map(changes => {
               return changes.map(a => {
                 const data = a.payload.doc.data() as Privada;
                 data.id = a.payload.doc.id;
                 return data;
               });
             });
           }
           else{
             this.privadasCollection = this._afs.collection('privada',x => x.where('nombre','==',this.ag.tipo.nombrePrivada));
             this.privadas = this.privadasCollection.snapshotChanges()
             .map(changes => {
               return changes.map(a => {
                 const data = a.payload.doc.data() as Privada;
                 data.id = a.payload.doc.id;
                 return data;
               });
             });
            }
          }
        }
      else{
        this.privadasCollection = this._afs.collection('privada', x => x.orderBy('nombre', 'asc'));
        this.privadas = this.privadasCollection.snapshotChanges()
        .map(changes => {
          return changes.map(a => {
            const data = a.payload.doc.data() as Privada;
            data.id = a.payload.doc.id;
            return data;
          });
        });
      }
      return this.privadas;
    }

    /**
    * metodo que obtiene el nombre de la privada
    * @param idPrivada identificador de la privada
    * @returns nombre privada
    **/
    getNombrePrivada(idPrivada){
      return this.privadasCollection.doc(idPrivada).valueChanges();
    }

    /**
    * metodo que obtiene os usuarios de una privada
    * @param idPrivada identificador de privada
    * @returns usuarios
    **/
    getUsuariosPrivada(idPrivada){
      const usuarios=[];
      const obj={};
      const query = this._afs.collection('usuario', ref => ref.where("privada","==",idPrivada))
      .snapshotChanges().map(changes => {
        return changes.map(a => {
          const data = a.payload.doc.data();
          data.id = a.payload.doc.id;
          return data;
        });
      });
      return query;
    }

    /**
    * metodo que obtiene una privada mediante su codigo de verificacion
    * @param codigo codigo de verificacion
    * @returns privada
    **/
    async getPrivadaCodigo(codigo){
      let integer=parseInt(codigo,10)
      let query = this._afs.collection('privada').ref
      .where('codigo', '==',integer);
      const snapshot = await query.get();
      snapshot.docs.map(doc=>({
       id:doc.id
      }))
      if(snapshot.empty==false){
        const idPrivada=snapshot.docChanges[0].doc.id;
        this.privadasCollection = this._afs.collection('privada', x => x.orderBy('nombre', 'asc'));
        return this.privadasCollection.doc(idPrivada).valueChanges().map(x=>{
          x.id=idPrivada;
          return x;
        })
      }
      else{
        return null
      }
    }


    /**
    * metodo que obtiene una privada mediante su codigo de verificacion
    * @param codigo codigo de verificacion
    * @returns privada
    **/
    async getPrivadaCodigoGuardia(codigo){
      let integer=parseInt(codigo,10)
      let query = this._afs.collection('privada').ref
      .where('codigo_guardia', '==',integer);
      const snapshot = await query.get();
      snapshot.docs.map(doc=>({
       id:doc.id
      }))
      if(snapshot.empty==false){
        const idPrivada=snapshot.docChanges[0].doc.id;
        this.privadasCollection = this._afs.collection('privada', x => x.orderBy('nombre', 'asc'));
        return this.privadasCollection.doc(idPrivada).valueChanges().map(x=>{
          x.id=idPrivada;
          return x;
        })
      }
      else{
        return null;
      }
    }

    /**
    * metodo que obtiene una privada
    * @param idPrivada identificador de privada
    * @returns Privada
    **/
    getPrivada(idPrivada) {
      return this.privadasCollection.doc(idPrivada).valueChanges();
    }

    /**
    * metodo que guarda una privada
    * @param privada objeto con datos de privada
    **/
    guardar(privada: Privada) {
      this.privadasCollection.add(privada);
    }

    /**
    * metodo que modifica una privada
    * @param idPrivada idPrivada identificador de privada
    * @param privada objeto ya modificado
    * @returns invitaciones
    **/
    editar(idPrivada: string, privada) {
      privada.codigo=parseInt(privada.codigo,10)
      this.privadasCollection.doc(idPrivada).update(privada);
    }
  }
