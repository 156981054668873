import { Injectable } from "@angular/core";
import { Http, Headers } from "@angular/http";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { AngularFireDatabase, AngularFireAction } from "angularfire2/database";
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
} from "angularfire2/firestore";
import { Observable } from "rxjs/Observable";
import { BehaviorSubject } from "rxjs/BehaviorSubject";
import { Subscription } from "rxjs/Subscription";
import "rxjs/add/operator/switchMap";
import "rxjs/add/operator/map";
import { AuthGuardService } from "./auth-guard.service";

@Injectable({
  providedIn: "root",
})
export class BitacoraService {
  bitacoraCollection;
  bitacora;
  constructor(private _afs: AngularFirestore, private ag: AuthGuardService) {}

  /**
   * metodo de que obtiene los accesos
   * @returns todos los accesos
   **/
  getAccesos() {
    var fecha = new Date();
    var año = fecha.getFullYear();
    var mes = fecha.getMonth() + 1;
    var dia = fecha.getDate();
    var fechaCompleta = "" + dia + "/" + mes + "/" + año;
    console.log(this.ag);
    if (this.ag.tipo.tipoUsuario == 4) {
      this.bitacoraCollection = this._afs.collection("acceso", (ref) =>
        ref
          .where("privada", "==", this.ag.tipo.privada)
          .where("dia", "==", fechaCompleta)
          .orderBy("createdAt", "desc")
      );
    } else if (this.ag.tipo.tipoUsuario === 3) {
      this.bitacoraCollection = this._afs.collection("acceso", (ref) =>
        ref
          .where("privada", "==", this.ag.tipo.privada)
          .orderBy("createdAt", "desc")
      );
    } else {
      this.bitacoraCollection = this._afs.collection("acceso", (x) =>
        x.orderBy("horaEntrada", "desc").where("dia", "==", fechaCompleta)
      );
    }

    this.bitacora = this.bitacoraCollection.snapshotChanges().map((changes) => {
      return changes.map((a) => {
        const data = a.payload.doc.data();
        data.id = a.payload.doc.id;
        return data;
      });
    });
    return this.bitacora;
  }

  filterBy(value, date, order) {
    this.bitacoraCollection = this._afs.collection("acceso", (ref) =>
      ref
        .where("privada", "==", this.ag.tipo.privada)
        .where("dia", "==", date)
        .orderBy(value, order)
    );

    this.bitacora = this.bitacoraCollection.snapshotChanges().map((changes) => {
      return changes.map((a) => {
        const data = a.payload.doc.data();
        data.id = a.payload.doc.id;
        return data;
      });
    });
    return this.bitacora;
  }

  filterByValue(field, value, date) {
    console.log(field, value, date);
    this.bitacoraCollection = this._afs.collection("acceso", (ref) =>
      ref
        .where("privada", "==", this.ag.tipo.privada)
        .where("dia", "==", date)
        .where(field, "==", value)
    );

    this.bitacora = this.bitacoraCollection.snapshotChanges().map((changes) => {
      return changes.map((a) => {
        const data = a.payload.doc.data();
        data.id = a.payload.doc.id;
        return data;
      });
    });
    return this.bitacora;
  }

  /**
   * metodo de que obtiene un acceso especifico
   * @param acceso privada acceso
   * @param id identificador de acceso
   * @returns un acceso
   **/
  getAcceso(id) {
    this.bitacoraCollection = this._afs.collection("acceso");
    return this.bitacoraCollection.doc(id).valueChanges();
  }
  /**
   * metodo de que permite guardar un acceso
   * @param bitacora acceso
   **/
  guardar(bitacora) {
    bitacora.idPrivada = this.ag.tipo.privada;
    bitacora.nombrePrivada = this.ag.tipo.nombrePrivada;
    this.bitacoraCollection = this._afs.collection("acceso");
    this.bitacoraCollection.add(bitacora);
  }
  /**
   * metodo de que permite editar un acceso
   * @param bitacora bitacora
   * @param id identificador de acceso
   **/
  editar(id, bitacota) {
    this.bitacoraCollection = this._afs.collection("acceso");
    this.bitacoraCollection.doc(id).update(bitacota);
  }
}
