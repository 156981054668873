import { Component, OnInit } from '@angular/core';
import { AuthGuardService } from 'app/servicios/auth-guard.service';
import { UsuarioService } from 'app/servicios/usuario.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap/modal/modal';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap/modal/modal-ref';

@Component({
  selector: 'app-verificacion-mati',
  templateUrl: './verificacion-mati.component.html',
  styleUrls: ['./verificacion-mati.component.scss']
})
export class VerificacionMatiComponent implements OnInit {
  JSON: JSON;
  objeto:any;
  modalRef: NgbModalRef;
  constructor(
    private ag:AuthGuardService,
    private usuarioService:UsuarioService,
    private modalService:NgbModal
  ) {
    this.JSON = JSON;
    this.objeto = {
      nombre:"",
      correo:"",
      celular:""
    }
  }


  ngOnInit() {
    console.log("inicializando mati")
    this.objeto=this.ag.tipo;
  }

  /**
  * Metodo despliega un modal
  **/
  async openModal(content) {
    this.modalRef = this.modalService.open(content, { size: 'sm' , centered:true});
  }

  async cerrar(){
    this.modalRef.close();
  }

}
